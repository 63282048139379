<template>
  <div class="my-account-layout">
    <my-account-navigation class="gray-border-circle" />
    <router-view class="my-account-content" />
  </div>
</template>

<script>
import MyAccountNavigation from '@/components/user/MyAccountNavigation'
export default {
  name: 'MyAccountLayout',
  components: {
    MyAccountNavigation
  }
}
</script>
<style lang="scss" scoped>
.my-account-layout{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.my-account-content{
  padding: 1rem;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  margin-top: 46px;
}
</style>
