<template>
  <div class="my-account-navigation">
    <a @click="goBack()">
      <i class="fal fa-long-arrow-left fa-2x" />
    </a>
    <span class="center navigation-title">{{ $t(title) }}</span>
    <a @click="goBack()">
      <i class="fal fa-times" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'MyAccountNavigation',
  data: () => {
    return {
      title: ''
    }
  },
  watch: {
    $route () {
      this.title = `pageMetaTitle.${this.$route.name}`
    }
  },
  created () {
    this.title = `pageMetaTitle.${this.$route.name}`
  },
  methods: {
    goBack () {
      switch (this.$route.name) {
        case 'EditUsername':
        case 'ChangePassword':
          this.$router.push({ name: 'MyAccount' })
          break
        case 'MyAccount':
          this.$router.push({ name: 'Home' })
          break
        default:
          this.$router.go('-1')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .navigation-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.44;
  }
</style>
